var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0"},[_c('v-card-title',{staticClass:"recipe-title py-0",attrs:{"primary-title":""}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-auto pa-0"},[(!_vm.inCart)?_c('v-btn',{staticClass:"mr-2 grey lighten-3",attrs:{"icon":"","x-small":"","fab":"","elevation":"3","color":"primary"},on:{"click":_vm.addToCart}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cart-plus ")])],1):_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","fab":"","dark":"","elevation":"3","color":"error"},on:{"click":_vm.remove}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cart-remove ")])],1)],1),_c('router-link',{attrs:{"to":("/recipe/" + _vm.id)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('v-col',{staticClass:"pa-0 text-center word-wrap",attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.name))])]}}])})],1)],1)],1)],1),_c('v-col',{staticClass:"col-auto pa-0"},[(_vm.userLoggedIn)?_c('v-card-actions',[_c('router-link',{attrs:{"to":("/recipe-form/" + _vm.id)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('v-btn',{staticClass:"grey lighten-3",attrs:{"icon":"","x-small":"","fab":"","elevation":"3","color":"primary","href":href},on:{"click":navigate}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,false,580528206)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }